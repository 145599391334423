<template>
  <NuxtLink :external="external" :rel="rel" :target="target" :to="href">
    <slot />
    <VIcon v-if="target === '_blank'" color="anchorIcon" size="small">
      $mdiOpenInNew
    </VIcon>
  </NuxtLink>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    href: string
    target?: '_blank' | null
    external?: boolean
  }>(),
  {
    target: null,
    external: false,
  }
)

const rel = computed(() => {
  if (props.target === '_blank') {
    return 'noopener noreferrer'
  }
  return undefined
})
</script>

<style scoped></style>
